/* eslint-disable no-unused-vars */
import Vue from "vue";
import firebaseConfig from "../../firebaseConfig";

import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

import store from "../store";

firebase.initializeApp(firebaseConfig);

export const firestorage = firebase.storage();
export const db = firebase.firestore();

const analytics = getAnalytics();

Vue.prototype.$firebase = firebase;
Vue.prototype.$analytics = analytics;
Vue.prototype.$analyticsLog = logEvent;

firebase.auth().onAuthStateChanged((user) => {
  store.dispatch("auth/user/SET_USER", user);
});

export default { firestorage, db, analytics };
