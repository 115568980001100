import Vue from "vue";
import VueGtag from "vue-gtag";
import router from "@/router/index.js";

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-X1T00PCFP5",
    },
  },
  router,
);
