import * as types from "./types";

export default {
  [types.GET_USER](state) {
    return state.data.user;
  },
  [types.GET_USER_DATA](state) {
    return state.data.userData;
  },
  [types.GET_TOKEN](state) {
    return state.data.token;
  },
  [types.GET_CLAIMS](state) {
    return state.data.claims;
  },
  [types.GET_FIREBASE_LOADED](state) {
    return state.data.firebaseLoaded;
  },
};
