<template>
  <v-app>
    <v-main>
      <router-view class="word-break" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    const user = this.$store.getters["auth/user/GET_USER"];
    window.ChannelIO(
      "boot",
      {
        pluginKey: "e6541c37-05a3-4119-8e7f-88b9c21a509c",
        memberId: user ? user.uid : undefined,
      },
      (error, user) => {
        if (error) {
          console.error(error);
        } else {
          window.channelUser = user;
        }
      },
    );
  },
};
</script>
