/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import authorization from "./modules/authorization";
import page from "./modules/page";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    auth: authorization,
    page: page,
  },
  plugins: [createPersistedState()],
});
