/* eslint-disable no-unused-vars */
import Vue from "vue";
import axios from "axios";
import store from "../store";
import API from "@/API/auth.js";

// axios 인스턴스 설정
const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://asia-northeast3-madeall.cloudfunctions.net/"
    : "http://localhost:5001/madeall/asia-northeast3/";

const firebaseAPI = axios.create({
  baseURL,
  timeout: 50000,
  headers: { "X-Custom-Header": "foobar" },
});

// 요청 인터셉터
firebaseAPI.interceptors.request.use(
  async (config) => {
    if (!store.getters["auth/user/GET_FIREBASE_LOADED"]) {
      await waitFirebase();
    }
    config.headers.authorization = await store.getters["auth/user/GET_TOKEN"];
    return config;
  },
  (error) => Promise.reject(error)
);

// 응답 인터셉터
firebaseAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    if (
      response &&
      response.status === 401 &&
      response.data.code === "auth/id-token-expired"
    ) {
      // 토큰 갱신 로직
      try {
        if (!store.getters["auth/user/GET_FIREBASE_LOADED"]) {
          waitFirebase();
        }
        const currentUser = await Vue.prototype.$firebase.auth().currentUser;
        if (currentUser) {
          await store.dispatch("auth/user/SET_USER", currentUser);
          config.headers.authorization = await store.getters[
            "auth/user/GET_TOKEN"
          ];
          return axios(config); // 갱신된 토큰으로 원본 요청 재시도
        }
      } catch (refreshError) {
        console.error("Token refresh error:", refreshError);
        store.dispatch("auth/user/SET_LOGOUT", "Home").then(() => {
          Vue.prototype.$toasted.global.notice("토큰 재발행 오류로 로그아웃 되었습니다");
          API.rebootChannelIo();
        });
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Firebase 로드 대기 함수
const waitFirebase = () =>
  new Promise((resolve, reject) => {
    let cnt = 0;
    const timer = setInterval(() => {
      if (store.getters["auth/user/GET_FIREBASE_LOADED"]) {
        clearInterval(timer);
        resolve();
      } else if (cnt++ > 500) {
        clearInterval(timer);
        reject(new Error("서버 연결을 실패했습니다."));
      }
    }, 10);
  });

Vue.prototype.$axios = firebaseAPI;
