import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// import ko from "@/locales/common/ko.json";
// import en from "@/locales/common/en.json";

// const messages = {
//   ko: ko,
//   en: en,
// };

function loadLocaleMessages() {
  const requireLang = require.context("@/locales/common", true, /\.json$/);
  const messages = {};
  // json file read
  for (const file of requireLang.keys()) {
    if (file === "./index.js") continue;
    const path = file.replace(/(\.\/|\.json$)/g, "").split("/");
    path.reduce((o, s, i) => {
      if (o[s]) return o[s];
      o[s] = i + 1 === path.length ? requireLang(file) : {};
      return o[s];
    }, messages);
  }
  return messages;
}

export const i18n = new VueI18n({
  locale: "ko",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});
