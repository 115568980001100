// namespace
export const NAMESPACE = "user";

// actions
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_CLAIMS = "SET_CLAIMS";
export const SET_FIREBASE_LOADED = "SET_FIREBASE_LOADED";
export const SET_LOGOUT = "SET_LOGOUT";

export const GET_USER = "GET_USER";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_TOKEN = "GET_TOKEN";
export const GET_CLAIMS = "GET_CLAIMS";
export const GET_FIREBASE_LOADED = "GET_FIREBASE_LOADED";

export const VISIT_USER = "VISIT_USER";
