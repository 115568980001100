import * as user from "./types";

// context === {dispatch: ƒ, commit: ƒ, getters: {…}, state: {…}, rootGetters: {…},…}
export default {
  [user.SET_LANGUAGE]({ commit }, payload) {
    commit(user.SET_LANGUAGE, payload);
  },
  [user.SET_THEME]({ commit }, payload) {
    commit(user.SET_THEME, payload);
  },
  [user.SET_SHOW_NOTICE]({ commit }, payload) {
    commit(user.SET_SHOW_NOTICE, payload);
  },
};
