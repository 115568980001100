import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 377, //fold폰
      sm: 750, //모바일
      md: 1250, //태블릿
      lg: 1904,
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background_light: "#FEFEFE",
        background_normal: "#F2F2F2",
        background_dark: "#DEDEDE",

        onSurface_light: "#575757",
        onSurface_normal: "#3C3C3C",
        onSurface_dark: "#222222",

        primary_light: "#f7fafe",
        primary_normal: "#249CF2",
        primary_dark: "#2A7FBC",

        error: "#E52E2E",
        warning: "#F2AA00",
        success: "#0AD11B",

        white: "#FFFFFF",
        black: "#000000",

        white_light_global: "FEFEFE",
        white_normal_global: "F2F2F2",
        white_dark_global: "DEDEDE",

        background: "#fff", //삭제 예정
        surface: "#f9f9f9", //삭제 예정
        onSurface: "#111", //삭제 예정
        subSurface: "#EEEEEE", //삭제 예정
        accent: "#82B1FF", //삭제 예정
        info: "#2196F3", //삭제 예정
        primary: "#0090FF", //삭제 예정
        secondary: "#36D1DC", //삭제 예정
      },
      dark: {
        background_light: "#222222",
        background_normal: "#3C3C3C",
        background_dark: "#575757",

        onSurface_light: "#DEDEDE",
        onSurface_normal: "#F2F2F2",
        onSurface_dark: "#FEFEFE",

        primary_light: "#24292E",
        primary_normarl: "#249CF2",
        primary_dark: "#f7fafe",

        error: "#FF5252",
        warning: "#E5AE06",
        success: "#59CC5D",

        white: "#000000",
        black: "#FFFFFF",

        white_light_global: "FEFEFE",
        white_normal_global: "F2F2F2",
        white_dark_global: "DEDEDE",

        background: "#000000", //삭제 예정
        surface: "#f9f9f9", //삭제 예정
        onSurface: "#111", //삭제 예정
        subSurface: "#EEEEEE", //삭제 예정
        accent: "#82B1FF", //삭제 예정
        info: "#2196F3", //삭제 예정
        primary: "#0090FF", //삭제 예정
        secondary: "#36D1DC", //삭제 예정
      },
    },
  },
});
