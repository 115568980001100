export const CURRENT_LANGUAGE = "CURRENT_LANGUAGE";
export const CURRENT_THEME = "CURRENT_THEME";
export const SHOW_NOTICE = "SHOW_NOTICE";

export default {
  [CURRENT_LANGUAGE]: (state) => {
    return state.data.language;
  },
  [CURRENT_THEME]: (state) => {
    return state.data.theme;
  },
  [SHOW_NOTICE]: (state) => {
    return state.data.showNotice;
  },
};
